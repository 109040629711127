import { Link } from "react-router-dom";
const content = {
    mainPage: {
        pageName: "L'essentiel sera trouvé",
        subMenuList: [
            {
                num: 1,
                label: "Pour le business",
                anchor: "MainPageForBusiness"
            },
            { num: 2, label: "Sécurité", anchor: "MainPageSafety" },
            { num: 3, label: "Plateforme", anchor: "MainPagePlatform" },
            { num: 4, label: "À propos de nous", anchor: "MainPageAboutUs" },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/mainPage/img1.jpg",
            img1Mobile: "./img/mainPage/img1Mobile.jpg",
            img1Tablet: "./img/mainPage/img1Tablet.jpg", // добавил главную картинку
            img2: "./img/mainPage/img2.jpg",
            img2Mobile: "./img/mainPage/img2Mobile.jpg",
            img2Tablet: "./img/mainPage/img2Tablet.jpg",
            img3: "./img/mainPage/img3.jpg",
            img3Mobile: "./img/mainPage/img3Mobile.jpg",
            img3Tablet: "./img/mainPage/img3Tablet.jpg"
        },
        blocks: {
            forBusiness: {
                num: 1,
                name: "Pour le business",
                textBlock1:
                    "Depuis 2016, notre centre situationnel numérique effectue une analyse complète de l'espace informationnel : nous surveillons des médias, des réseaux sociaux, des messageries, ainsi que des informations dans le darkweb. Nous assurons une interaction efficace avec les publics cibles, protégeons les entreprises contre les attaques d'information, assurons une prévention précoce et une élimination en temps réel de divers risques et menaces.",
                textBlock2:
                    "Externalisation de la sécurité économique. Audit de la réputation et détection précoce des menaces pour un développement durable. Surveillance des concurrents et de l'environnement externe, ainsi qu'analyse médiatique personnalisée.",
                linkName1: "En savoir plus",
                link1: "/forBusinessPage",
                anchor: "MainPageForBusiness"
            },
            safety: {
                num: 2,
                name: "Sécurité",
                textBlock1:
                    "La sécurité de l'information pour les entreprises est l'un des aspects clés d'un travail réussi, car elle protège les données confidentielles et la propriété intellectuelle. L'intégration de solutions modernes en matière de sécurité de l'information est aujourd'hui un élément obligatoire de la stratégie de développement durable des entreprises, car cela permet de prévenir les pertes économiques causées par les cyberattaques et les fuites de données.",
                textBlock2:
                    "Les entreprises et les organismes de réglementation du monde entier imposent des exigences de plus en plus strictes en matière de protection de l'information, et l'ignorance de telles mesures peut entraîner des conséquences juridiques et financières graves.",
                linkName1: "En savoir plus",
                link1: "/safetyPage",
                anchor: "MainPageSafety"
            },
            platform: {
                num: 3,
                name: "Plateforme",
                textBlock1:
                    "Les produits basés sur la plateforme technologique Avalanche offrent au client les capacités d'un centre situationnel moderne en termes de collecte, de traitement, de stockage et d'analyse de grandes quantités de données à partir de sources ouvertes.",
                textBlock2:
                    "L'utilisation des produits Avalanche améliore considérablement la connaissance des dirigeants sur les événements importants grâce à l'introduction de nouvelles solutions hautement technologiques assurant une analyse continue et automatisée du flux de données provenant de sources ouvertes.",
                linkName1: "En savoir plus",
                link1: "/platformPage",
                anchor: "MainPagePlatform"
            },
            situationCenter: {
                name: "Centre situationnel",
                textBlock1:
                    "Externalisation du centre situationnel. Fourniture d'une surveillance en continu du champ d'information autour de l'objet d'intérêt et de la détection des risques et menaces émergents sans avoir besoin de créer son propre centre situationnel en louant nos spécialistes et solutions techniques.",
                textBlock2:
                    "Les outils logiciels sélectionnent automatiquement les informations les plus importantes en appliquant un prétraitement et une analyse logico-syntaxique des textes, les présentent de manière la plus pratique possible, et génèrent également des rapports statistiques et analytiques sur les événements les plus importants.",
                linkName: "En savoir plus",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Analyse d'audience",
                textBlock1:
                    "Avalanche aide à collecter et traiter rapidement de grandes quantités de données provenant des médias sociaux. C'est un outil indispensable pour les professionnels de différents domaines, des relations publiques et du marketing aux renseignements d'entreprise. Analyse de l'audience cible, croissance des abonnés, leur activité et bien plus encore.",
                textBlock2:
                    "L'analyse des données publiques sur les médias sociaux est effectuée sans violer la vie privée des utilisateurs. Elle permet de détecter les vagues d'information et les attaques de comptes faux. La rapidité d'obtention des données, la transparence et l'objectivité de l'information sont assurées.",
                linkName: "En savoir plus",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Analyse de vulnérabilité",
                textBlock1:
                    "Les cybercriminels modernes découvrent et exploitent de plus en plus de nouvelles vulnérabilités sur les sites web contenant des données sensibles. Les erreurs d'implémentation de programmes, la mise à jour tardive des logiciels, les mots de passe obsolètes et bien d'autres facteurs conduisent tous à l'apparition de vulnérabilités importantes dans les projets Internet.",
                textBlock2:
                    "Scanner web est un ensemble de solutions visant à assurer les fonctions de contrôle de la sécurité d'un système d'information construit sur la base de technologies web. Le scanner web permet de mettre en œuvre des fonctions de détection, de prévention et de réponse aux tentatives d'activités non autorisées des utilisateurs distants et internes des réseaux informatiques.",
                linkName: "En savoir plus",
                link: "/scannerPage"
            },
            analytics: {
                name: "Analytique",
                textBlock1:
                    "Contrôle de l'information et des options pour résoudre les problèmes qui se posent, réagir aux risques et menaces, faire face aux attaques d'information. Évaluation de l'efficacité des communications stratégiques, des campagnes publicitaires et d'autres interactions publiques avec le public cible et repositionnement sur des questions vulnérables.",
                textBlock2:
                    "Recherche documentaire dans l'intérêt des services de marketing, de relations publiques et de sécurité économique. Analytics fonctionne comme un service d'assistance et aide à trouver des réponses aux questions les plus difficiles.",
                linkName: "En savoir plus",
                link: "/analyticsPage"
            },
            unnamedBlock: {
                textBlock:
                    "La substitution d'importations des fabricants étrangers et le développement de la compétitivité russe afin de faire face aux risques de sanctions dans le domaine des hautes technologies."
            },
            aboutUs: {
                num: 4,
                name: "À propos de nous",
                textBlock1:
                    "Le produit nommé Avalanche est apparu pour la première fois sur le marché russe en 2003. Son créateur, un pionnier dans le domaine de l'espionnage concurrentiel, le lieutenant-colonel à la retraite de l'Agence fédérale pour les communications et l'information gouvernementales auprès du président de la Fédération de Russie, Andrei Masalovitch. Pendant près de 20 ans, le système a été utilisé par les forces de l'ordre, les autorités fédérales et régionales pour surveiller les sentiments publics et lutter contre la menace terroriste.",
                textBlock2:
                    "Aujourd'hui, les systèmes analytiques construits sur la base de la plate-forme technologique Avalanche sont activement utilisés par les sociétés commerciales pour surveiller les marchés russes et étrangers, les partenaires et les concurrents.",
                linkName1: "En savoir plus",
                link1: "/aboutUsPage",
                anchor: "MainPageAboutUs"
            },
            shortBlock: {
                name: "Synergie des technologies d'analyse de Big Data"
            },
            demonstration: {
                num: "",
                name: "Manifestation",
                textBlock2: `Pour démontrer le travail d'Avalanche, nous avons pré-configuré plusieurs projets:\n\r\u2022  dirigeants politiques dans les médias étrangers \n\r\u2022  ministères et départements des médias russes \r\n\u2022  COVID-19 dans les régions russes \r\n Pour plus d'informations, contactez nous.`
            },
            sixCardsBlock: [
                {
                    num: 1,
                    name: "Dirigeants politiques",
                    text: "Mentions des principaux dirigeants politiques mondiaux dans les médias étrangers.",
                    linkName: "Accéder",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministères",
                    text: "Agenda des ministères et des agences russes dans les médias nationaux.",
                    linkName: "Accéder",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Mentions du Covid-19 dans les médias régionaux russes, classées par district fédéral.",
                    linkName: "Accéder",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Recherche de billets d'avion.",
                    text: "Collecte d'informations sur les vols bon marché et les offres de diverses compagnies aériennes dans le monde.",
                    linkName: "Accéder",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Actualités sur la mobilisation",
                    text: "Toutes les informations importantes sur la campagne de mobilisation et les missions de volontariat.",
                    linkName: "Accéder",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersécurité",
                    text: "Actualités du monde du renseignement et de la cybersécurité, surveillance des fuites et recherche de vulnérabilités.",
                    linkName: "Accéder",
                    externalLink: "https://osint.avl.team/"
                }
            ],
            cardsWithImg: [
                {
                    num: 1,
                    text: "Les gens. Le succès est obtenu grâce au travail acharné de notre équipe professionnelle. Avalanche est un complexe exploité par du personnel technique qualifié, composé d'opérateurs, d'analystes et d'administrateurs système.",
                    imgLink: "./img/mainPage/small1.jpg"
                },
                {
                    num: 2,
                    text: "Processus. Formation et analyse de la carte des risques et des menaces pour la réputation. Définition de scénarios d'attaques informatiques possibles. Développement de mécanismes de contrôle de l'audience dans le domaine de l'information et optimisation du travail avec les médias.",
                    imgLink: "./img/mainPage/small2.jpg"
                },
                {
                    num: 3,
                    text: "Technologies. Un ensemble étendu d'algorithmes modernes de traitement analytique de données massives, comprenant une analyse rapide, une évaluation des risques, des prévisions et bien plus encore.",
                    imgLink: "./img/mainPage/small3.jpg"
                }
            ],
            cardsBlock: {
                cards: [
                    {
                        num: 1,
                        text: "Les gens. Le succès est obtenu grâce au travail acharné de notre équipe professionnelle. ",
                        imgLink: "./img/mainPage/small1Mobile.jpg"
                    },
                    {
                        num: 2,
                        text: "Processus. Formation et analyse de la carte des risques et des menaces pour la réputation. ",
                        imgLink: "./img/mainPage/small2Mobile.jpg"
                    },
                    {
                        num: 3,
                        text: "Technologies. Un jeu d'algorithmes avancé modernes pour le traitement des données massives. ",
                        imgLink: "./img/mainPage/small3Mobile.jpg"
                    }
                ]
            }
        }
    },
    platform: {
        pageName: "Plateforme",
        subMenuList: [
            { num: 1, label: "Plateforme", anchor: "PlatformPagePlatform" },
            { num: 2, label: "Nos produits", anchor: "PlatformPageProducts" },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/platformPage/img1.jpg",
            img1Mobile: "./img/platformPage/img1Mobile.jpg"
        },
        blocks: {
            imageBlock: {
                name: "",
                textBlock1: "",
                textBlock2:
                    "Avalanche est une technologie de renseignement sur Internet et une famille de systèmes automatisés de surveillance sur Internet de nouvelle génération, construits sur cette base. Elle combine des capacités avancées de collecte et de traitement analytique de l'information, ainsi que de la planification opérationnelle rapide des activités.",
                linkName1: "",
                link1: "",
                anchor: "PlatformPagePlatform"
            },
            ourProducts: {
                num: 2,
                name: "Nos produits",
                anchor: "PlatformPageProducts"
            },
            situationCenter: {
                name: "Centre situationnel.",
                textBlock1:
                    "Externalisation du centre situationnel. Assurer une surveillance 24 heures sur 24 du champ d'information autour de l'objet d'intérêt et identifier les risques et menaces émergents sans créer votre propre centre situationnel, en louant nos spécialistes et nos solutions techniques.",
                textBlock2:
                    "Les outils logiciels sélectionnent automatiquement les informations les plus importantes et, en utilisant une pré-traitement et une analyse syntaxique et logique des textes, les présentent de la manière la plus pratique possible, ainsi que génèrent des rapports statistiques et analytiques sur les événements les plus importants.",
                linkName: "En savoir plus",
                link: "/situationCenterPage"
            },
            analysis: {
                name: "Analyse d'audience",
                textBlock1:
                    "Avalanche aide à collecter et traiter rapidement de grandes quantités de données provenant des médias sociaux. C'est un outil indispensable pour les professionnels de différents secteurs, allant des relations publiques et du marketing à l'intelligence d'entreprise. Il permet l'analyse de l'audience cible, la croissance des abonnés, leur activité et bien plus encore.",
                textBlock2:
                    "L'analyse des données ouvertes des médias sociaux est effectuée sans enfreindre la vie privée des utilisateurs. Elle permet de détecter les vagues d'informations et les attaques de faux comptes. La collecte de données est rapide, les informations sont transparentes et objectives.",
                linkName: "En savoir plus",
                link: "/audiencePage"
            },
            vulnerabilitiesScan: {
                name: "Analyse de vulnérabilité",
                textBlock1:
                    "Les cybercriminels modernes découvrent et exploitent de nouvelles vulnérabilités sur les sites web qui contiennent des données sensibles. Les erreurs de mise en œuvre des programmes, les mises à jour de logiciels non effectuées en temps voulu, les mots de passe obsolètes et bien plus encore, tout cela conduit à l'apparition de graves vulnérabilités dans les projets internet.",
                textBlock2:
                    "Scanner web est un ensemble de solutions visant à assurer les fonctions de contrôle de la sécurité du système d'information construit sur la base de technologies web. Le scanner web permet de mettre en œuvre les fonctions de détection, de prévention et de réaction aux tentatives d'activité non autorisée des utilisateurs distants et internes des réseaux informatiques.",
                linkName: "En savoir plus",
                link: "/scannerPage"
            },
            analytics: {
                name: "Analytique",
                textBlock1:
                    "Le contrôle de l'agenda médiatique et les options pour résoudre les problèmes qui se posent, la réaction aux risques et aux menaces, la lutte contre les attaques informatiques. Évaluation de l'efficacité des communications stratégiques, des campagnes publicitaires et d'autres interactions publiques avec le public cible, ainsi que la repositionnement sur les questions vulnérables.",
                textBlock2:
                    "Recherche documentaire dans l'intérêt des services de marketing, de relations publiques et de sécurité économique. Analytics fonctionne comme un service d'assistance et aide à trouver des réponses aux questions les plus difficiles.",
                linkName: "En savoir plus",
                link: "/analyticsPage"
            }
        },
        sixCardsBlock: [
            {
                num: 1,
                name: "Réglage cible",
                text: "Le réglage fin des ressources d'information, avec la possibilité de nettoyer les informations importantes des contenus non pertinents, des bannières, etc. Collecter les informations les plus importantes en éliminant les informations non pertinentes."
            },
            {
                num: 2,
                name: "En ligne 24h/24 et 7j/7",
                text: "Surveillance et notification 24h/24. Même la nuit. Même les jours fériés. Toujours à l'écoute de vos intérêts. Si une menace est détectée, vous recevrez une notification dans Telegram."
            },
            {
                num: 3,
                name: "Intégration",
                text: "Mécanismes flexibles d'intégration et d'échange de données avec des systèmes d'information tiers. Avalanche peut agir à la fois comme source de données et comme outil d'analyse."
            },
            {
                num: 4,
                name: "Analyse et visualisation",
                text: "Analyse des doublons et coloration émotionnelle. Visualisation des connexions et des schémas de propagation de l'information. Toutes les données sont adaptées à la visualisation."
            },
            {
                num: 5,
                name: "Vue à 360 degrés",
                text: "Le flux d'événements ne comprend pas seulement les canaux de médias et de télégrammes traditionnels, mais également les événements d'entreprise, les tribunaux, les appels d'offres, les sites Web gouvernementaux et bien plus encore. Vous obtenez une vue complète des événements."
            },
            {
                num: 6,
                name: "Met en surbrillance l'essentiel",
                text: `Les événements importants et les articles dans les grands médias et les chaînes Telegram sont mis en surbrillance verte, la diffamation et les informations négatives en surbrillance jaune. Les événements critiques sont en rouge. Le système "feu tricolore" fonctionne sur la base de méthodes statistiques et de réseaux neuronaux éduqués.`
            }
        ],
        cardsBlock: {
            cards: [
                {
                    num: 1,
                    name: "Réglage cible",
                    text: "Le réglage fin des ressources d'information, avec la possibilité de nettoyer les informations importantes des contenus non pertinents, des bannières, etc. Collecter les informations les plus importantes en éliminant les informations non pertinentes."
                },
                {
                    num: 2,
                    name: "En ligne 24h/24 et 7j/7",
                    text: "Surveillance et notification 24h/24. Même la nuit. Même les jours fériés. Toujours à l'écoute de vos intérêts. Si une menace est détectée, vous recevrez une notification dans Telegram."
                },
                {
                    num: 3,
                    name: "Intégration",
                    text: "Mécanismes flexibles d'intégration et d'échange de données avec des systèmes d'information tiers. Avalanche peut agir à la fois comme source de données et comme outil d'analyse."
                },
                {
                    num: 4,
                    name: "Analyse et visualisation",
                    text: "Analyse des doublons et coloration émotionnelle. Visualisation des connexions et des schémas de propagation de l'information. Toutes les données sont adaptées à la visualisation."
                },
                {
                    num: 5,
                    name: "Vue à 360 degrés",
                    text: "Le flux d'événements ne comprend pas seulement les canaux de médias et de télégrammes traditionnels, mais également les événements d'entreprise, les tribunaux, les appels d'offres, les sites Web gouvernementaux et bien plus encore. Vous obtenez une vue complète des événements."
                },
                {
                    num: 6,
                    name: "Met en surbrillance l'essentiel",
                    text: `Les événements importants et les articles dans les grands médias et les chaînes Telegram sont mis en surbrillance verte, la diffamation et les informations négatives en surbrillance jaune. Les événements critiques sont en rouge. Le système "feu tricolore" fonctionne sur la base de méthodes statistiques et de réseaux neuronaux éduqués.`
                }
            ]
        }
    },
    forBusiness: {
        pageName: "Pour le business",
        subMenuList: [
            {
                num: 1,
                label: "Surveillance de la concurrence pour votre entreprise",
                anchor: "ForBusinessMain"
            },
            {
                num: 2,
                label: "Centre situationnel virtuel",
                anchor: "ForBusinessCenter"
            },
            {
                num: 3,
                label: "Licences et implémentation",
                anchor: "ForBusinessLicence"
            },
            {
                num: 4,
                label: "Services et analyses",
                anchor: "ForBusinessService"
            },
            {
                num: 5,
                label: "Communication",
                anchor: "ForBusinessCommunications"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/forBusinessPage/img1.jpg",
            img1Mobile: "./img/forBusinessPage/img1Mobile.jpg",
            img2: "./img/forBusinessPage/img2.jpg",
            img2Mobile: "./img/forBusinessPage/img2Mobile.jpg",
            img3: "./img/forBusinessPage/img3.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Surveillance de la concurrence pour votre entreprise",
                textBlock2:
                    "Les projets dans Avalanche assurent un contrôle total de l'espace d'information. Grâce à eux, vous pourrez suivre la situation sur le marché, l'activité des concurrents, les changements dans la politique gouvernementale et la réglementation normative et juridique. Chaque projet est unique et est configuré en fonction du thème de l'industrie.",

                anchor: "ForBusinessMain"
            },
            center: {
                num: 2,
                name: "Centre situationnel virtuel",
                textBlock1: "",
                textBlock2:
                    "Le service de surveillance de l'espace Internet fournit au client les capacités d'un centre situationnel moderne pour surveiller la situation opérationnelle et détecter rapidement les menaces Internet. Trois plans tarifaires sont prévus pour les clients commerciaux.",
                linkName1: "Tarifs",
                link1: "/tariffsPage",
                anchor: "ForBusinessCenter"
            },
            communications: {
                num: 5,
                name: "Communication",
                textBlock1: "",
                textBlock2:
                    "La réputation commerciale et la confiance des clients sont acquises au fil des ans, mais peuvent être détruites instantanément par une attaque d'information de la part des concurrents. Toute action publique de l'entreprise peut devenir une cible d'agression informationnelle, faisant partie des dures réalités des affaires russes.",
                linkName1: "En savoir plus",
                link1: "/communicationsPage",
                anchor: "ForBusinessCommunications"
            },
            service: {
                num: 4,
                name: "Services et analyses",
                textBlock1: "",
                textBlock2:
                    "Avalanche est plus efficace lorsqu'il est combiné avec nos services de conseil. Nous ne nous contenterons pas de configurer le projet selon vos besoins d'information, mais nous préparerons également une analyse régulière, évaluerons les risques potentiels et mettrons en évidence les points de croissance.",
                linkName1: "En savoir plus",
                link1: "/analyticsPage",
                anchor: "ForBusinessService"
            },
            licence: {
                num: 3,
                name: "Licences et implémentation",
                textBlock1: "",
                textBlock2:
                    "Mise en place du logiciel Avalanche sur l'équipement du client, y compris la configuration initiale et la formation des utilisateurs. Gestion de la surveillance au sein de l'entreprise, sans limitation du nombre d'utilisateurs et du nombre d'objets surveillés.",
                linkName1: "Tarifs",
                link1: "/tariffsPage",
                anchor: "ForBusinessLicence"
            }
        }
    },
    situationCenter: {
        pageName: "Centre situationnel",
        subMenuList: [
            {
                num: 1,
                label: "Centre situationnel",
                anchor: "SituationCenterMain"
            },
            {
                num: 2,
                label: "À propos des centres situationnels",
                anchor: "SituationCenterAbout"
            },
            {
                num: 3,
                label: "Avantages des centres situationnels",
                anchor: "SituationCenterBenifits"
            },
            {
                num: 4,
                label: "Externalisation du centre situationnel",
                anchor: "SituationCenterOutsourse"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/situationCenter/img1.jpg",
            img1Mobile: "./img/situationCenter/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Centre situationnel",
                textBlock2:
                    "Depuis 2016, notre centre situationnel effectue une surveillance globale de l'espace d'information : nous contrôlons l'agenda des médias, des réseaux sociaux, des messageries, ainsi que les informations sur le darkweb. Plus de 50 opérateurs et analystes travaillent au sein du centre situationnel pour détecter et prévenir les risques et les menaces dans l'intérêt des autorités fédérales, des entreprises publiques, des grandes entreprises commerciales et du secteur bancaire.",

                anchor: "SituationCenterMain"
            },
            about: {
                num: 2,
                name: "À propos des centres situationnels",
                textBlock2:
                    "Le Centre situationnel est une structure créée pour le suivi opérationnel de l'environnement informationnel et la consolidation des informations pour la prise de décisions de gestion compétentes. En règle générale, un centre situationnel se compose de trois éléments : un groupe de surveillance, un groupe d'analystes et un groupe d'intervention rapide.",

                anchor: "SituationCenterAbout"
            },
            benifits: {
                num: 3,
                name: "Avantages des centres situationnels",
                textBlock2:
                    "En cas de situation d'urgence ou d'attaque d'information, la rapidité de prise de décision peut dépendre de la réputation de toute l'entreprise. La tâche clé du centre situationnel est de prévenir à l'avance du danger imminent, de fournir toutes les informations nécessaires sur la situation, ainsi que de fournir des ressources d'information pour une réaction adéquate.",

                anchor: "SituationCenterBenifits"
            },
            outsourse: {
                num: 4,
                name: "Externalisation du centre situationnel",
                textBlock2:
                    "La création et le maintien d'un centre situationnel complet sont très coûteux, même pour les plus grandes entreprises. Le coût de l'équipement d'un centre situationnel commence à partir de 10 millions de roubles, et le coût de la maintenance à partir de 1 million de roubles par mois. Nous possédons notre propre centre situationnel avec un effectif total de plus de 50 personnes et proposons un nouveau service unique sur le marché russe - l'externalisation d'un centre situationnel.",

                anchor: "SituationCenterOutsourse"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Groupe de surveillance",
                        text: "Contrôle l'espace d'information, recherche les risques et les menaces pour l'entreprise, surveille en permanence l'environnement et les concurrents."
                    },
                    {
                        num: 2,
                        name: "Groupe d'analyse",
                        text: "Effectue une évaluation des risques et des menaces identifiés, forme une compréhension de la situation et fournit une stratégie de réponse."
                    },
                    {
                        num: 3,
                        name: "Groupe d'intervention rapide",
                        text: "Fournit une neutralisation des risques et des menaces apparus dans les médias, les réseaux sociaux ou les messageries instantanées."
                    },
                    {
                        num: 4,
                        name: "Coordination des efforts",
                        text: "Grâce aux efforts conjoints du groupe, ils fournissent une sécurité complète pour tous les segments de l'arrière-plan de l'information."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Contrôle permanent de l'agenda",
                        text: "Contrairement à un service de relations publiques à plein temps, le centre situationnel de l'entreprise assurera une surveillance constante et 24 heures sur 24 de l'espace d'information, même la nuit et le week-end."
                    },
                    {
                        num: 2,
                        name: "Alerte précoce",
                        text: "Les opérateurs des centres situationnels sont les premiers à recevoir l'information dès qu'elle vient d'entrer dans l'espace public et qu'elle n'a pas encore été diffusée. Par conséquent, nos clients disposent toujours de suffisamment de temps pour réagir préventivement à une menace. La notification des menaces détectées est en temps réel."
                    },
                    {
                        num: 3,
                        name: "Développement d'une contre-mesure",
                        text: "L'évaluation des risques, acquisition et analyse des données pour la prise de décision de gestion optimale et pour l'élaboration de la stratégie contre toute menace. "
                    },
                    {
                        num: 4,
                        name: "Réaction",
                        text: "Grâce aux efforts conjoints du groupe, ils fournissent une sécurité complète pour tous les segments de l'arrière-plan de l'information."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Coût optimal",
                        text: "L'entreprise bénéficiera d'une gamme complète de services du centre situationnel sans le coût de sa création et les frais de maintenance."
                    },
                    {
                        num: 2,
                        name: "Évolutivité",
                        text: "Le centre situationnel peut travailler aussi bien pour les petites entreprises que pour les grandes entreprises menant des activités internationales."
                    },
                    {
                        num: 3,
                        name: "Flexibilité",
                        text: "Les employés du centre situationnel sont immergés dans les processus et les problèmes de l'industrie et peuvent travailler dans divers domaines."
                    },
                    {
                        num: 4,
                        name: "Déploiement rapide",
                        text: "Le centre situationnel est déployé et adapté à une nouvelle thématique au cours de la semaine. Cela donne de la flexibilité et de l'adaptabilité dans la prise de décision."
                    }
                ]
            }
        }
    },
    communications: {
        pageName: "Communication",
        subMenuList: [
            {
                num: 1,
                label: "Communication",
                anchor: "CommunicationsMain"
            },
            {
                num: 2,
                label: "Protection contre l'agression de l'information",
                anchor: "CommunicationsDefence"
            },
            {
                num: 3,
                label: "Gestion de la réputation",
                anchor: "CommunicationsReputation"
            },
            {
                num: 4,
                label: "Formation du fond d'information",
                anchor: "CommunicationsBackground"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/communicationsPage/img1.jpg",
            img1Mobile: "./img/communicationsPage/img1Mobile.jpg",
            img2Mobile: "./img/communicationsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Communication",
                textBlock2:
                    "La réputation commerciale et la confiance des clients sont acquises au fil des ans, mais peuvent être détruites instantanément par une attaque d'information de la part des concurrents. Toute action publique de l'entreprise peut devenir une cible d'agression d'information - c'est devenu une partie des dures réalités des affaires en Russie.",

                anchor: "CommunicationsMain"
            },
            defence: {
                num: 2,
                name: "Protection contre l'agression de l'information",
                textBlock1:
                    "Les entreprises se disputent les consommateurs non seulement sur le marché, mais aussi dans l'espace de l'information. Tout changement dans l'environnement du marché ou l'action publique de l'entreprise peut entraîner le début d'une attaque informationnelle destructrice par les concurrents.",
                textBlock2:
                    "Nous assurons la protection et la prévention contre l'agression d'information, en surveillant non seulement en continu l'espace informationnel, mais également en aidant à éviter les pertes de réputation. Pour chaque client, nous développons une carte de risques et de menaces uniques pour comprendre les objectifs potentiels et les sources d'attaque, ainsi que pour aider à donner une réponse adéquate à l'agression d'information dans les médias, les réseaux sociaux et les messageries.",

                anchor: "CommunicationsDefence"
            },
            reputation: {
                num: 3,
                name: "Gestion de la réputation",
                textBlock2:
                    "L'aide pour rétablir la e-réputation, supprimer ou bloquer les informations illégales et aussi pour assister la protection des intérêts. ",

                anchor: "CommunicationsReputation"
            },
            background: {
                num: 4,
                name: "Formation du fond d'information",
                textBlock2:
                    "Prévenir est plus facile et moins cher que guérir. La formation d'un fond d'information positif par rapport à l'entreprise permettra de réduire drastiquement les coûts liés à la guerre de l'information. La promotion d'un programme d'information positif est une condition importante pour créer un environnement d'information favorable.",

                anchor: "CommunicationsBackground"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Temps",
                        text: "La principale ressource au cours de crise est le temps.  Les départements des relations publiques et les entrepreneurs manquent le plus souvent le début d'une attaque informatique et perdent du temps. "
                    },
                    {
                        num: 2,
                        name: "Externalisation",
                        text: "Notre société est la première en Russie à fournir des services d'externalisation de centres situationnels. Cela signifie que le fond d'information sur l'entreprise sera sous contrôle constant 24 heures sur 24 et sept jours sur sept. La supervision est effectuée par des analystes qualifiés qui identifient les risques et les menaces pour l'entreprise avant même qu'elle n'entre dans la phase de mise en œuvre."
                    },
                    {
                        num: 3,
                        name: "Réaction",
                        text: "Nous fournissons non seulement une détection précoce des attaques d'informations, mais proposons également une stratégie de résolution des problèmes et une réponse adéquate aux risques et menaces émergents dans les médias."
                    },
                    {
                        num: 4,
                        name: "Unicité",
                        text: "Il n'y a pas de place pour les modèles pour repousser l'agression informationnelle. Pour chaque cas, nous développons une méthodologie unique de protection contre les menaces informatiques."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/communicationsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Audit de réputation",
                        text: "Le principal produit d'un audit de réputation est une cartographie des risques et des menaces qui montre toutes les faiblesses de la stratégie de communication et du positionnement de l'entreprise. La carte analyse les risques actuels et potentiels qui peuvent survenir en raison de changements au sein de l'entreprise ou dans l'environnement externe."
                    },
                    {
                        num: 2,
                        name: "Neutralisation",
                        text: "Un système de réponse à l'agression informationnelle est élaboré sur la base d'une carte des risques et des menaces. Des matériaux sont préparés à l'avance pour leur diffusion, des scénarios de réponse sont élaborés, une stratégie de réponse de communication est développée et des matériaux appropriés sont créés, qui sont ensuite diffusés dans les médias, les réseaux sociaux et les messageries."
                    }
                ]
            },
            block3: {
                cards: [
                    {
                        num: 1,
                        name: "Stratégie et positionnement",
                        text: "Développement d'une stratégie efficace de positionnement dans l'espace de l'information, qui fournira les positions les plus avantageuses pour la protection contre les risques et les menaces de l'information."
                    },
                    {
                        num: 2,
                        name: "Processus de création de sens ",
                        text: "Travailler avec le public cible. Création du sens et des associations pour façonner l'opinion publique souhaitée."
                    },
                    {
                        num: 3,
                        name: "Défense proactive",
                        text: "Prévision des risques potentiels. Préparation de positions de communication efficaces qui permettront d'atténuer le premier et le plus douloureux coup."
                    },
                    {
                        num: 4,
                        name: "Gestion de l'agenda",
                        text: "Nous aidons les entreprises à devenir des centres d'expertise, des sources d'agenda et d'opinions plutôt que de simplement suivre le discours actuel. Nos conférenciers deviennent des vecteurs de connaissances uniques et des centres d'attraction pour le public cible."
                    }
                ]
            }
        }
    },
    analytics: {
        pageName: "Analytique",
        subMenuList: [
            {
                num: 1,
                label: "Analytique",
                anchor: "AnalyticsMain"
            },
            {
                num: 2,
                label: "Renseignement d'origine sources ouvertes",
                anchor: "AnalyticsScouting"
            },
            {
                num: 3,
                label: "Recherche",
                anchor: "AnalyticsResearch"
            },
            { label: "Soumettre une demande", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/analyticsPage/img1.jpg",
            img1Mobile: "./img/analyticsPage/img1Mobile.jpg",
            img2Mobile: "./img/analyticsPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Analytique",
                textBlock1:
                    "La plupart des pertes financières d'une entreprise s'expliquent par les mauvaises décisions prises en raison du manque d'informations et de l'incertitude. Notre société fournit des renseignements et un soutien analytique aux entreprises. Ces services seront particulièrement utiles dans les réalités complexes et ambiguës du commerce international d'aujourd'hui.",
                textBlock2: `Notre mission est de fournir une quantité suffisante d'informations pour prendre une décision de gestion de n'importe quelle complexité, même dans les conditions les plus complexes et ambiguës. Notre département d'analyse et de recherche est organisé selon le principe de la "think tank" ("fabrique de pensée"), ce qui permet de résoudre efficacement tous les problèmes d'information des clients.`,
                anchor: "AnalyticsMain"
            },
            scouting: {
                num: 2,
                name: "Renseignement d'origine sources ouvertes",
                textBlock1:
                    "OSINT (Open Source Intelligence) est une technique de collecte de renseignements à partir de sources ouvertes, qui permet d'obtenir des informations pour les entreprises, même dans des conditions de secret et de manque de sources de données. Le OSINT fonctionne sur le principe de l'étude de cas : en utilisant des informations provenant de sources ouvertes, une image complète et fiable des événements est construite pour répondre à toute question, même la plus complexe.",
                textBlock2:
                    "Nos analystes possèdent une technologie universelle pour travailler avec l'information, ce qui nous permet de collecter des données et de clarifier la situation dans les cas les plus difficiles.Une analyse du champ d'information est effectuée pour comprendre une situation spécifique et former une prévision fiable de son évolution.",

                anchor: "AnalyticsScouting"
            },
            research: {
                num: 3,
                name: "Recherche",
                textBlock2:
                    "Notre équipe mène non seulement des études de bureau, mais également des études sur le terrain. Nous menons des études quantitatives et qualitatives dans toutes les régions du pays, tant dans le domaine de l'économie et du marketing que dans le domaine socio-politique.",

                anchor: "AnalyticsResearch"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Sources",
                        text: "Depuis plus de 15 ans de travail, nous avons accumulé un grand nombre de sources d'information : nous sommes abonnés à toutes les plus grandes bases de données et sources d'information."
                    },
                    {
                        num: 2,
                        name: "Compétences uniques",
                        text: "Nos analystes font un stage spécialisé aux programmes d'Andrei Masalovich, c'est pourquoi nous puissions obtenir des informations exclusives à partir de sources ouvertes. "
                    },
                    {
                        num: 3,
                        name: "Expertise",
                        text: "Nous collaborons avec plus de deux cents experts dans divers domaines de connaissances, des relations publiques, du droit et de l'économie à la robotique et à l'aviation spatiale. Nos clients peuvent être assurés que les informations qu'ils reçoivent sont confirmées par des experts de haut niveau."
                    },
                    {
                        num: 4,
                        name: "Équipe",
                        text: "Nous avons rassemblé une équipe de spécialistes uniques en leur genre, comprenant des politologues, des experts en relations publiques, des journalistes et des analystes. Chacun d'entre eux a non seulement parcouru son propre chemin de développement professionnel, mais a également acquis des connaissances spéciales au sein de l'entreprise. La synthèse de l'expérience et des compétences uniques nous permet de relever des défis de toute complexité."
                    }
                ]
            },
            block2: {
                imageUrl: "../img/analyticsPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Recherche qualitative",
                        text: "Nous sommes spécialisés dans la conduite de groupes de discussion et la conduite d'entretiens avec des experts. Cela permet à nos clients de former une politique de communication et de marketing efficace, ainsi que de la corriger en temps opportun."
                    },
                    {
                        num: 2,
                        name: "Recherche quantitative",
                        text: "Sur la base de la carte des risques et des menaces, un système de réaction à l'agression informationnelle est élaboré. Des scénarios de réaction sont préparés à l'avance, une stratégie de réponse de communication est élaborée."
                    }
                ]
            }
        }
    },
    safety: {
        pageName: "Sécurité",
        subMenuList: [
            {
                num: 1,
                label: "Sécurité",
                anchor: "SafetyMain"
            },
            {
                num: 2,
                label: "Sécurité économique",
                anchor: "SafetyEconomic"
            },
            {
                num: 3,
                label: "Soutien juridique",
                anchor: "SafetySupport"
            },
            {
                num: 4,
                label: "Cybersécurité",
                anchor: "SafetyCyber"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/safetyPage/img1.jpg",
            img1Mobile: "./img/safetyPage/img1Mobile.jpg",
            img2Mobile: "./img/safetyPage/img2Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Sécurité",
                textBlock1:
                    "La principale raison des pertes financières dans les entreprises est la prise de décisions erronées due à une pénurie d'informations et d'incertitude. Nous offrons un soutien de renseignement et d'analyse aux entreprises. Ces services seront particulièrement utiles dans les réalités complexes et ambiguës du monde des affaires international moderne.",
                textBlock2: `La mission de notre entreprise est de fournir suffisamment d'informations pour prendre des décisions de gestion complexes même dans les conditions les plus difficiles et ambiguës. Notre département d'analyse et de recherche est organisé selon le principe de la "think tank" ("fabrique de pensée"), ce qui permet de résoudre efficacement tous les problèmes d'information du client.`,
                anchor: "SafetyMain"
            },
            economic: {
                num: 2,
                name: "Sécurité économique",
                textBlock2:
                    "Sous réserve du respect de la législation de la Fédération de Russie, notre entreprise assure la sécurité économique de l'entreprise en menant une due diligence complète sur les transactions, les partenaires, les employés et les candidats de l'entreprise.",
                anchor: "SafetyEconomic"
            },
            cyber: {
                num: 4,
                name: "Cybersécurité",
                textBlock2:
                    "Depuis plus de 15 ans, nous développons et mettons en œuvre des solutions de sécurité pour les agences gouvernementales fédérales, les sociétés d'État, les grandes entreprises commerciales et le secteur bancaire.",

                anchor: "SafetyCyber"
            },
            support: {
                num: 3,
                name: "Soutien juridique",
                textBlock2:
                    "Un soutien juridique fiable sur un large éventail de questions économiques, allant des conflits corporatifs aux questions de faillite et de gestion judiciaire. Cela permet non seulement de fournir aux clients les informations nécessaires pour prendre des décisions de gestion éclairées, mais également de protéger leurs intérêts dans les litiges et les affaires judiciaires à toutes les étapes.",
                anchor: "SafetySupport"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Vérification des contreparties",
                        text: "La vérification des contreparties est un processus visant à recueillir des informations exhaustives sur les partenaires potentiels d'une entreprise afin de prendre la meilleure décision possible. Ce travail est effectué à la fois sur les contreparties russes et étrangères, y compris les informations provenant des réseaux sociaux et de nombreux autres sources d'informations."
                    },
                    {
                        num: 2,
                        name: "Vérification des candidats",
                        text: "La réputation des candidats potentiels est vérifiée, les menaces et les risques qui peuvent provenir d'un employé potentiel sont déterminés. "
                    },
                    {
                        num: 3,
                        name: "Défense proactive",
                        text: "Parmi les analystes de l'entreprise, il y a d'anciens employés des forces de l'ordre et des structures spéciales qui ont une vaste expérience dans l'enquête sur les crimes économiques et d'autres actes illégaux."
                    },
                    {
                        num: 4,
                        name: "Recherche",
                        text: "Nous pouvons résoudre pratiquement n'importe quelle question, de la recherche d'actifs à l'étranger à l'analyse de l'activité des sociétés offshore. Nous savons où trouver des informations sur une entreprise chinoise, sur la dernière activité d'un homme d'affaires d'Amérique latine, ou évaluer les risques de la sortie d'une entreprise sur le marché de l'Asie du Sud-Est."
                    }
                ]
            },
            block2: {
                cards: [
                    {
                        num: 1,
                        name: "Recherche de vulnérabilités",
                        text: "Des recherches uniques sur des logiciels propriétaires qui identifient les vulnérabilités des ressources d'information, des sites web et des applications. Non seulement la recherche de problèmes, mais aussi le développement de solutions pour les éliminer."
                    },
                    {
                        num: 2,
                        name: "Développement de logiciels",
                        text: "Le développement des solutions logicielles spécifiques pour la sécurité de l'infrastructure."
                    },
                    {
                        num: 3,
                        name: "Développement de services analytiques",
                        text: "Développement d'outils pour l'analyse approfondie des réseaux sociaux : des publications, de la dynamique et de l'activité de l'audience. Analyse du trafic des sites Web et du trafic de recherche."
                    },
                    {
                        num: 4,
                        name: "Analyse des données",
                        text: "La collecte et le traitement de grandes quantités de données (Big Data), ainsi que le développement de solutions personnalisées pour optimiser le travail avec de gros volumes d'informations."
                    }
                ]
            },
            block3: {
                imageUrl: "../img/safetyPage/middle1.jpg",
                cards: [
                    {
                        num: 1,
                        name: "Protection de la réputation",
                        text: `Les avocats de "Masalovich et partenaires" peuvent aider à restaurer la réputation commerciale en cas de diffamation dans les médias, supprimer ou bloquer des informations illégales et fournir une assistance pour défendre les intérêts du client en cas d'attaque d'information.`
                    },
                    {
                        num: 2,
                        name: "Litiges commerciaux",
                        text: "Nous ne fournissons pas seulement des informations actualisées sur la situation liée au litige d'entreprise, mais nous pouvons également fournir une assistance juridique consultative et, si nécessaire, représenter les intérêts du client devant les tribunaux de la Fédération de Russie."
                    }
                ]
            }
        }
    },
    learning: {
        pageName: "Formation",
        subMenuList: [
            {
                num: 1,
                label: "Renseignement sur la concurrence",
                anchor: "LearningService"
            },
            {
                num: 2,
                label: "Formations en entreprise",
                anchor: "LearningCorporate"
            },
            { label: "Soumettre une demande", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/learningPage/img1.jpg",
            img1Mobile: "./img/learningPage/img1Mobile.jpg"
        },
        blocks: {
            service: {
                num: 1,
                name: "Renseignement sur la concurrence",
                textBlock1:
                    "Dans le monde moderne, l'information est une ressource clé pour le développement réussi de toute entreprise. La collecte de renseignements sur les sources ouvertes (Open Source Intelligence, OSINT) joue un rôle crucial dans la collecte et l'analyse de diverses données. Grâce à la disponibilité des informations ouvertes, la collecte de renseignements sur les sources ouvertes facilite le processus d'analyse de données et de prévision de l'évolution de la situation.",
                textBlock2:
                    "Nous vous expliquerons comment OSINT peut aider votre entreprise à utiliser l'information de manière optimale, à améliorer la prise de décision stratégique et à préserver la confidentialité, ainsi qu'à détecter les menaces et les risques liés aux activités des concurrents. Nos méthodologies vous permettront de détecter rapidement les menaces et les opportunités pour votre entreprise, de renforcer votre position sur le marché et d'atteindre de nouveaux sommets.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningService"
            },
            corporate: {
                num: 2,
                name: "Formation corporative",
                textBlock1:
                    "Le développement qualitatif des employés est l'un des facteurs clés de succès de toute entreprise. C'est pourquoi les formations en entreprise sont une partie intégrante d'une stratégie commerciale efficace. ",
                textBlock2:
                    "Nos formations aideront à acquérir des connaissances appliquées dans le domaine de l'OSINT et à assurer le leadership dans l'environnement d'information pour un développement continu des affaires.",
                linkName1: "",
                linkName2: "",
                anchor: "LearningCorporate"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Niveau de base",
                        text: "Les bases de la collecte de renseignements à partir de sources ouvertes (OSINT) et de la recherche sur Internet."
                    },
                    {
                        num: 2,
                        name: "Niveau intermédiaire",
                        text: "Les outils OSINT efficaces pour travailler sur Internet et les réseaux sociaux, garantir sa propre sécurité."
                    },
                    {
                        num: 3,
                        name: "Niveau avancé",
                        text: "Recherche dans l'IoT, travail avec WireShark, détection de Honeypot et autres outils avancés de l'OSINT."
                    }
                ]
            }
        }
    },
    audience: {
        pageName: "Analyse d'audience",
        subMenuList: [
            {
                num: 1,
                label: "Collecte de statistiques",
                anchor: "AudienceStatistic"
            },
            {
                num: 2,
                label: "Outils",
                anchor: "AudienceTools"
            },
            {
                num: 3,
                label: "Possibilités",
                anchor: "AudiencePossible"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/audiencePage/img1.jpg",
            img1Mobile: "./img/audiencePage/img1Mobile.jpg"
        },
        blocks: {
            helper: {
                num: 1,
                name: "Collecte de statistiques",
                textBlock1:
                    "Avalanche aide à collecter et traiter rapidement de grandes quantités de données des médias sociaux. C'est un outil indispensable pour les professionnels de divers domaines, allant des relations publiques et du marketing à l'intelligence d'entreprise. Il permet l'analyse de l'audience cible, la croissance des abonnés, leur activité et bien plus encore.",
                textBlock2:
                    "Nous effectuons l'analyse des données publiques dans les médias sociaux. Cela permet de détecter les vagues d'information et les attaques des comptes faux (botnets). La rapidité de la collecte des données, la transparence et l'objectivité de l'information sont assurées.",
                anchor: "AudienceStatistic"
            },
            tools: {
                num: 2,
                name: "Outils",
                textBlock1: "",
                textBlock2: "",
                anchor: "AudienceTools"
            },
            possibilities: {
                num: 3,
                name: "Possibilités",
                textBlock1:
                    "L'utilisateur informe le système des groupes et des pages qui l'intéressent, puis après la période de collecte et de traitement des données, il reçoit les premiers résultats. L'analyse des données est effectuée pour la dernière année. Le système traite plusieurs paramètres clés: le nombre d'utilisateurs, de publications, de likes, de commentaires et de vues.",
                textBlock2:
                    "Le système regroupe les informations et les affiche sous une forme interactive pratique.\n\n Pour connaître les réseaux actuellement pris en charge, écrivez-nous.",
                linkName1: "",
                linkName2: "",
                anchor: "AudiencePossible"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "Surveillance secrète de l'activité de sources de menaces spécifiques."
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Analyse de l'intérêt et évaluation de la réaction du public cible dans les réseaux mondiaux à certains problèmes et sujets."
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Analyse des tensions sociales"
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Suivi de l'activité des communautés sur les réseaux sociaux."
                    }
                ]
            }
        }
    },
    scanner: {
        pageName: "Analyseur de vulnérabilité",
        subMenuList: [
            {
                num: 1,
                label: "Menaces modernes",
                anchor: "ScannerPageDanger"
            },
            {
                num: 2,
                label: "Contrôle de la sécurité",
                anchor: "ScannerPageControl"
            },
            {
                num: 3,
                label: "Possibilités",
                anchor: "ScannerPagePossibilities"
            },
            {
                num: 4,
                label: "Avantages",
                anchor: "ScannerPageBenifits"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/scannerPage/img1.jpg",
            img1Mobile: "./img/scannerPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Menaces modernes",
                textBlock1:
                    "Aujourd'hui, les ressources Web sont des éléments indispensables des processus commerciaux. De nombreuses études montrent que plus de 40% des ressources contiennent des vulnérabilités critiques. Les problèmes de sécurité des ressources Web sont des problèmes dans les processus commerciaux de l'organisation. Ils peuvent entraîner:",
                textBlock2:
                    "• la divulgation d'informations confidentielles;\n• La diminution des bénéfices en raison de la violation des processus commerciaux;\n• un impact négatif sur la réputation de l'entreprise;\n• Un accès non autorisé aux données personnelles des clients.;\n• la pénétration des contrevenants dans les systèmes d'information internes de l'entreprise.",
                anchor: "ScannerPageDanger"
            },
            control: {
                num: 2,
                name: "Contrôle de la sécurité",
                textBlock1:
                    "Les cybercriminels modernes découvrent et exploitent de plus en plus de vulnérabilités sur les ressources web qui contiennent des données sensibles. Des erreurs de mise en œuvre de logiciels, des mises à jour de logiciels non opportunes, des mots de passe obsolètes et bien plus encore, tout cela conduit à l'apparition de vulnérabilités graves dans les projets Internet.",
                textBlock2:
                    "Le scanner web est un ensemble de solutions visant à assurer les fonctions de contrôle de la sécurité d'un système d'information construit sur la base de technologies web. Le scanner web permet de mettre en œuvre des fonctions de détection, de prévention et de réaction aux tentatives d'activités non autorisées d'utilisateurs à distance et internes des réseaux informatiques.",
                anchor: "ScannerPageControl"
            },
            possibilities: {
                num: 3,
                name: "Possibilités",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPagePossibilities"
            },
            unnamed: {
                textBlock:
                    "Les méthodes de contrôle de la sécurité des sites Web prennent en compte les menaces modernes, les risques commerciaux de l'entreprise et les exigences des régulateurs."
            },
            benifits: {
                num: 4,
                name: "Avantages",
                textBlock1: "",
                textBlock2: "",
                anchor: "ScannerPageBenifits"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Vulnérabilités de l'architecture",
                    text: "Ce type de vulnérabilités concerne les erreurs commises lors de la création d'applications Web. Une vérification complète est effectuée pour détecter les injections de code malveillant depuis l'extérieur, l'accès non autorisé, la présence de virus. La vérification est effectuée à différents niveaux, de l'interface (vulnérabilités XSS / CS- RF), à la base de données (injections SQL / NoSQL, injections de code exécutable, etc.), la conformité du traitement des données utilisateur, la présence de protection contre les bots."
                },
                {
                    num: 2,
                    name: "Vulnérabilités de fichiers et de répertoires",
                    text: `La sécurité des fichiers et des répertoires fait référence aux vulnérabilités liées aux fichiers et aux dossiers stockés sur un site Web. Les scanners de sécurité effectuent une vérification complète pour détecter les fichiers et les dossiers qui sont ouverts au public, y compris ceux sans liens directs. Ils détectent les sources potentielles de fuites d'informations confidentielles et permettent de contrôler l'apparition de données indésirables dans les index des moteurs de recherche. La vérification inclut également la détection de la présence de données "sensibles" indexées par les moteurs de recherche.`
                },
                {
                    num: 3,
                    name: "Scanners de configuration ",
                    text: "On analyse la configuration du système d'information. La présence d'erreurs/faiblesses dans la configuration du complexe logiciel-matériel (DNS, configuration du serveur Web, etc.) est déterminée."
                },
                {
                    num: 4,
                    name: "Erreurs administratives",
                    text: "Au cours de l'installation et de l'exploitation d'une application web, de nombreuses erreurs courantes peuvent être commises, qui peuvent avoir des conséquences très regrettables. Cela peut inclure l'utilisation de mots de passe courants, la présence d'outils d'administration tiers, ainsi que la présence de code source tiers."
                },
                {
                    num: 5,
                    name: "Phénomènes sociaux négatifs",
                    text: "Recherche de toutes sortes d'informations négatives sur un site web: publications sur les forums de sécurité concernant les vulnérabilités découvertes, demandes de piratage du site web ou d'attaques par déni de service, divulgation d'informations sur les mécanismes de protection utilisés. Si le site web est construit sur un CMS typique, l'utilisateur reçoit des notifications concernant les problèmes détectés dans ce système. "
                },
                {
                    num: 6,
                    name: "Génération de rapports",
                    text: "Les résultats du travail des scanners sont présentés sous forme de rapport unique et clair."
                }
            ],
            block1Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "Vulnérabilités de l'architecture",
                        text: "Ce type de vulnérabilités concerne les erreurs commises lors de la création d'applications Web. Une vérification complète est effectuée pour détecter les injections de code malveillant depuis l'extérieur, l'accès non autorisé, la présence de virus."
                    },
                    {
                        num: 2,
                        name: "Vulnérabilités de fichiers et de répertoires",
                        text: "La sécurité des fichiers et des répertoires fait référence aux vulnérabilités liées aux fichiers et aux dossiers stockés sur un site Web."
                    },
                    {
                        num: 3,
                        name: "Scanners de configuration ",
                        text: "On analyse la configuration du système d'information. La présence d'erreurs/faiblesses dans la configuration du complexe logiciel-matériel (DNS, configuration du serveur Web, etc.) est déterminée."
                    },
                    {
                        num: 4,
                        name: "Erreurs administratives",
                        text: "Au cours de l'installation et de l'exploitation d'une application web, de nombreuses erreurs courantes peuvent être commises, qui peuvent avoir des conséquences très regrettables. Cela peut inclure l'utilisation de mots de passe courants, la présence d'outils d'administration tiers, ainsi que la présence de code source tiers."
                    },
                    {
                        num: 5,
                        name: "Phénomènes sociaux négatifs",
                        text: "Recherche de toutes sortes d'informations négatives sur un site web: publications sur les forums de sécurité concernant les vulnérabilités découvertes, demandes de piratage du site web ou d'attaques par déni de service, divulgation d'informations sur les mécanismes de protection utilisés."
                    },
                    {
                        num: 6,
                        name: "Génération de rapports",
                        text: "Les résultats du travail des scanners sont présentés sous forme de rapport unique et clair."
                    }
                ]
            },
            block2: [
                {
                    num: 1,
                    name: "",
                    text: `Surveillance constante du niveau de sécurité des sites de l'organisation en mode "24/7/365"`,
                    imgLink: "./img/scannerPage/small1.jpg"
                },
                {
                    num: 2,
                    name: "",
                    text: "Notification des personnes responsables en cas de détection de vulnérabilités critiques",
                    imgLink: "./img/scannerPage/small2.jpg"
                },
                {
                    num: 3,
                    name: "",
                    text: "Rapports hebdomadaires réguliers sur le niveau de sécurité actuel",
                    imgLink: "./img/scannerPage/small3.jpg"
                },
                {
                    num: 4,
                    name: "",
                    text: "Recommandations pour éliminer les vulnérabilités découvertes et assistance dans leur mise en œuvre",
                    imgLink: "./img/scannerPage/small4.jpg"
                },
                {
                    num: 5,
                    name: "",
                    text: "Réduction des coûts liés à la mise en œuvre des exigences de sécurité de l'information.",
                    imgLink: "./img/scannerPage/small5.jpg"
                },
                {
                    num: 6,
                    name: "",
                    text: "Il n'y a pas de charge supplémentaire sur les ressources de calcul de l'organisation.",
                    imgLink: "./img/scannerPage/small6.jpg"
                }
            ],
            block2Mobile: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: `Surveillance constante du niveau de sécurité des sites de l'organisation en mode "24/7/365"`,
                        imgLink: "./img/scannerPage/small1.jpg"
                    },
                    {
                        num: 2,
                        name: "",
                        text: "Notification des personnes responsables en cas de détection de vulnérabilités critiques",
                        imgLink: "./img/scannerPage/small2.jpg"
                    },
                    {
                        num: 3,
                        name: "",
                        text: "Rapports hebdomadaires réguliers sur le niveau de sécurité actuel",
                        imgLink: "./img/scannerPage/small3.jpg"
                    },
                    {
                        num: 4,
                        name: "",
                        text: "Recommandations pour éliminer les vulnérabilités découvertes et assistance dans leur mise en œuvre",
                        imgLink: "./img/scannerPage/small4.jpg"
                    },
                    {
                        num: 5,
                        name: "",
                        text: "Réduction des coûts liés à la mise en œuvre des exigences de sécurité de l'information.",
                        imgLink: "./img/scannerPage/small5.jpg"
                    },
                    {
                        num: 6,
                        name: "",
                        text: "Il n'y a pas de charge supplémentaire sur les ressources de calcul de l'organisation.",
                        imgLink: "./img/scannerPage/small6.jpg"
                    }
                ]
            }
        }
    },
    aboutUs: {
        pageName: "À propos de nous",
        subMenuList: [
            {
                num: 1,
                label: "Andrei Igorevich Masalovich",
                anchor: "AboutUsPageMain"
            },
            {
                num: 2,
                label: "Notre mission",
                anchor: "AboutUsPageMission"
            },
            {
                num: 3,
                label: "Directions d'activité",
                anchor: "AboutUsPageDirection"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/aboutUsPage/img1.jpg",
            img1Mobile: "./img/aboutUsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: 1,
                name: "Andrei Igorevich Masalovich",
                textBlock1:
                    "Pour la première fois, un produit appelé Avalanche est apparu sur le marché russe en 2003. Son créateur est un pionnier sur le marché de l'intelligence concurrentielle, le lieutenant-colonel à la retraite de l'Agence fédérale pour la communication et l'information gouvernementales Andrei Masalovich. Depuis près de 20 ans, le système est utilisé pour contrôler l'opinion publique et lutter contre la menace terroriste par les forces de l'ordre, les autorités fédérales et régionales.",
                textBlock2:
                    "Aujourd'hui, les systèmes analytiques construits sur la base de la plateforme technologique Avalanche sont largement utilisés par des entreprises commerciales pour surveiller les marchés russes et étrangers, les partenaires et les concurrents.",
                linkName1: "",
                linkName2: "",
                anchor: "AboutUsPageMain"
            },
            education: {
                name: "Enseignment",
                textBlock1: "",
                textBlock2: `2005 - cours "Gouvernance d'entreprise", Université York (Toronto, Canada)\n1994 - Cours de "Conversion & Privatization", Université de Los Angeles (USA)\n1992 - Cours spécialisé en anglais à l'école King's School of English (Londres, Royaume-Uni)\n1989 – Doctorat en sciences physiques et mathématiques, Institut d'aviation de Moscou (MAI)\n1984 - Institut d'aviation de Moscou, Faculté de mathématiques appliquées`
            },
            expert: {
                name: "Expert en renseignement concurrentiel",
                textBlock1: "",
                textBlock2: `Président du Consortium "Inforus"\nDoctorat en sciences physiques et mathématiques\nLauréat de la bourse de l'Académie des sciences de Russie "Scientifique éminent de Russie" - 1993.\nPrix IPCC "Meilleur travail en journalisme informatique en Russie" - 1995.\nLa récompense "Top-Profy" (MKK, catégories "Business" et "Personnalité") - 2002.`
            },
            unnamed: {
                name: "",
                textBlock1: `L'auteur a plus de 100 publications imprimées, dont les principaux résultats ont été brevetés aux États-Unis. Il a été publié à plusieurs reprises dans des revues telles que "Questions d'électronique", PC Week, "Monde de l'ordinateur", "Intercomputer", "Softmarket", "Marché des valeurs mobilières" et autres. En 1995, il a remporté le prix de la meilleure publication en journalisme informatique de Russie de l'Association internationale des journalistes IPCC pour un cycle d'articles sur la logique floue. Il a participé et a été membre du comité d'organisation de nombreux congrès internationaux. En 1995, il a reçu les remerciements du comité de direction d'EuroPar pour sa contribution personnelle à l'organisation de la conférence EuroPar-95 (Stockholm). Il a participé et dirigé plus de 20 projets internationaux réussis. Depuis 1998, il a organisé le développement d'un ensemble de logiciels analytiques pour les entreprises de commerce et les holdings. Le programme "Gestion de supermarché" a remporté le concours "Business-soft'98". En 2000, il a créé la société "InterRusSoft" pour réaliser des projets internet sur commande sur le marché mondial.`,
                textBlock2: `Il a réalisé plusieurs projets Internet réussis, notamment www.trubka.ru, www.rusbiotech.ru, www.procurezone.com. Ces projets ont remporté plusieurs prix dans des concours Internet tels que "Prix du jury" (InterNIT-2001), le diplôme "Golden Site - 2001", et autres. En 2001, il est devenu président du consortium Inforus, qui regroupe plus de 80 principales sociétés informatiques de Russie. Il a été expert pour le RFBR (1995-2000), INTAS (1998), le Centre de technologie de l'ONU (depuis 2002) et l'APEC (depuis 2003). Il est membre du jury des concours BIT (depuis 2004) et "Prix Runet" (depuis 2005). Il est également le développeur et le concepteur principal de la technologie Avalanche pour l'analyse des big data.\n\nLes technologies d'analyse de données massives sont très pertinentes, aussi bien dans le domaine des affaires que dans le secteur public.`
            },
            mission: {
                num: 2,
                name: "Notre mission",
                textBlock1: "",
                textBlock2: `La société "Masalovich et partenaires" a été fondée en 2020 par le célèbre expert en sécurité de l'information, Andrei Masalovich, mais l'histoire de "Masalovich et partenaires" remonte à 2003, lorsque le Consortium Inforus a été fondé et la première version du système de surveillance Avalanche a été créée. Depuis plus de 17 ans, notre expertise et notre expérience ont aidé les entreprises russes et les autorités gouvernementales à contrer avec succès les menaces de communication et d'information. Nous avons à notre actif de nombreux grands projets informatiques et une expérience de soutien analytique de l'information pour les organes gouvernementaux fédéraux et régionaux et leurs dirigeants, les corporations d'Etat et les entreprises commerciales, ainsi que l'industrie de la défense russe.`,
                anchor: "AboutUsPageMission"
            },
            direction: {
                num: 3,
                name: "Directions d'activités",
                textBlock1: "",
                textBlock2: `Notre équipe se spécialise dans la fourniture de la sécurité globale en utilisant la collecte et l'analyse des données des sources ouvertes, y compris la sécurité des communications, la sécurité économique et la sécurité de l'information.\n\nEn outre, le développement de logiciels d'entreprise est l'un des principaux domaines d'activité de l'organisation.`,
                anchor: "AboutUsPageDirection"
            }
        }
    },
    demonstration: {
        pageName: "Démonstration",
        subMenuList: [
            {
                num: 1,
                label: "Démonstration",
                anchor: "DemonstrationPageMain"
            },
            {
                num: 2,
                label: "Projets publiques",
                anchor: "DemonstrationPublicProjects"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/demonstrationPage/img1.jpg"
        },
        blocks: {
            PublicProjects: {
                num: 2,
                name: "Projets publiques",
                textBlock1:
                    "Pour démontrer le fonctionnement d'Avalanche, nous avons préconfiguré plusieurs projets publics. Le système agrège des informations à partir de milliers de sources ouvertes, les filtre par différents sujets et les présente sous forme de flux de nouvelles. C'est gratuit.",
                textBlock2:
                    "En se basant sur les informations traitées, le système analyse l'intérêt de l'audience cible pour certains problèmes dans les réseaux mondiaux et avertit des menaces et des risques émergents.",
                linkName1: "",
                linkName2: "",
                anchor: "DemonstrationPublicProjects"
            },
            iframe: {
                textBlock:
                    "Écran du directeur (mode par défaut). 8 principaux sujets pour surveiller les informations sur la thématique Covid 19. Surveillance de la situation opérationnelle sur Internet dans les districts fédéraux de la Fédération de Russie.",

                anchor: "DemonstrationPublicProjects"
            }
        },
        cards: {
            block1: [
                {
                    num: 1,
                    name: "Dirigeants politiques",
                    text: "Mentions des principaux dirigeants politiques mondiaux dans les médias étrangers.",
                    linkName: "Accéder",
                    externalLink: "https://leaders.avl.team/"
                },
                {
                    num: 2,
                    name: "Ministères",
                    text: "L'agenda des ministères et départements russes dans les médias nationaux.",
                    linkName: "Accéder",
                    externalLink: "https://minister.avl.team/"
                },
                {
                    num: 3,
                    name: "Covid-19",
                    text: "Mentions du coronavirus dans les médias régionaux de Russie, réparties par districts fédéraux.",
                    linkName: "Accéder",
                    externalLink: "https://covid19.avl.team/"
                },
                {
                    num: 4,
                    name: "Recherche de billets d'avion",
                    text: "Collecte d'informations sur les vols pas chers et les promotions de diverses compagnies aériennes mondiales.",
                    linkName: "Accéder",
                    externalLink: "https://lowcost.avl.team/"
                },
                {
                    num: 5,
                    name: "Actualités de la mobilisation",
                    text: "Tout ce qu'il faut savoir sur la campagne de mobilisation et les missions bénévoles.",
                    linkName: "Accéder",
                    externalLink: "https://vstavai.avl.team/"
                },
                {
                    num: 6,
                    name: "OSINT&Cybersec",
                    text: "Actualités du monde du renseignement et de la cybersécurité, surveillance des fuites et recherche de vulnérabilités.",
                    linkName: "Accéder",
                    externalLink: "https://osint.avl.team/"
                }
            ]
        }
    },
    contacts: {
        pageName: "Contacts",
        subMenuList: "",
        blocks: {
            contacts: {
                num: "",
                name: "Comment nous trouver:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span>
                                Moscou, rue 2 Ostankinskaya, 6, salle 103
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span>+7 (499) 393-00-15</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span>
                                Pour les questions générales: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span>Département des ventes: sales@avl.team</span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>5.</span>
                            <span>Soutien technique support@avl.team</span>
                        </div>
                    </>
                )
            },
            contactsTablet: {
                num: "",
                name: "Comment nous trouver:",
                textBlock1: "",
                textBlock2: (
                    <>
                        <div className="contacts-line-back bborder">
                            <span>1.</span>
                            <span className="text-right pl-15">
                                Moscou, rue 2 Ostankinskaya, 6, salle 103
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>2.</span>
                            <span className="text-right">
                                +7 (499) 393-00-15
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>3.</span>
                            <span className="text-right">
                                Pour les questions générales: info@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>4.</span>
                            <span className="text-right">
                                Département des ventes: sales@avl.team
                            </span>
                        </div>
                        <div className="contacts-line-back bborder">
                            <span>5.</span>
                            <span className="text-right">
                                Soutien technique support@avl.team
                            </span>
                        </div>
                    </>
                )
            }
        }
    },
    cookies: {
        pageName: "Comment fonctionnent les cookies",
        subMenuList: [
            {
                num: 1,
                label: "Politique relative aux cookies",
                anchor: "CookiesPagePolitics"
            },
            {
                num: 2,
                label: "Que sont les cookies?",
                anchor: "CookiesPageWhatItIs"
            },
            {
                num: 3,
                label: "Comment utilisons-nous les cookies?",
                anchor: "CookiesPageWhatWeUse"
            },
            {
                num: 4,
                label: "Quels types de cookies utilisons-nous?",
                anchor: "CookiesPageWhatTypes"
            },
            {
                num: 4,
                label: "Puis-je modifier mes paramètres de cookies?",
                anchor: "CookiesPageCanChange"
            }
        ],
        imageUrls: {
            img1: "./img/cookiesPage/img1.jpg",
            img1Mobile: "./img/cookiesPage/img1Mobile.jpg"
        },
        blocks: {
            politics: {
                num: 1,
                name: "Politique relative aux cookies",
                textBlock1:
                    "La présente Politique d'utilisation des cookies explique ce que sont les cookies, quels types de cookies nous utilisons, ainsi que comment gérer les paramètres des cookies. Pour plus d'informations sur la façon dont nous utilisons, stockons et protégeons vos données personnelles, veuillez consulter notre Politique de confidentialité.",
                textBlock2:
                    "Vous pouvez modifier ou retirer votre consentement à l'utilisation de cookies sur notre site web à tout moment.",
                anchor: "CookiesPagePolitics"
            },
            whatItIs: {
                num: 2,
                name: "Que sont les cookies?",
                textBlock2:
                    "Les fichiers cookies sont de petits fichiers texte utilisés pour stocker de petits fragments d'informations. Ils sont enregistrés sur votre appareil lorsque le site Web est chargé dans votre navigateur. Les fichiers cookies nous aident à assurer le bon fonctionnement du site Web, à le rendre plus sécurisé, à améliorer l'interaction avec l'utilisateur, à comprendre comment le site Web fonctionne, et à analyser ce qui ne fonctionne pas et où des améliorations sont nécessaires.",
                anchor: "CookiesPageWhatItIs"
            },
            whatWeUse: {
                num: 3,
                name: "Comment utilisons-nous les cookies?",
                textBlock2:
                    "Comme la plupart des services en ligne, notre site Web utilise ses propres fichiers cookies et des fichiers cookies tiers à plusieurs fins. Certains d'entre eux sont principalement nécessaires pour assurer le bon fonctionnement du site Web et ne collectent pas de données personnelles. Les fichiers cookies tiers utilisés sur notre site Web sont destinés à comprendre comment fonctionne le site Web, comment vous interagissez avec notre site Web, à assurer la sécurité de nos services, à fournir des publicités pertinentes, etc.",

                anchor: "CookiesPageWhatWeUse"
            },
            whatTypes: {
                num: 4,
                name: "Quels types de cookies utilisons-nous?",

                anchor: "CookiesPageWhatTypes"
            },
            canChange: {
                num: 5,
                name: "Puis-je modifier mes paramètres de cookies?",
                textBlock1: `Si vous décidez de modifier les paramètres plus tard, vous pouvez cliquer sur l'onglet "Politique de confidentialité et utilisation des cookies" sur votre écran. Cela affichera à nouveau la notification d'accord, vous permettant de modifier vos préférences ou de retirer complètement votre consentement.`,
                textBlock2:
                    "De plus, différents navigateurs fournissent différentes façons de bloquer et de supprimer les fichiers cookies utilisés par les sites Web. Vous pouvez modifier les paramètres de votre navigateur pour bloquer/supprimer les fichiers cookies.",
                anchor: "CookiesPageCanChange"
            },
            cookies: {
                analytics: {
                    num: "",
                    name: "cookielawinfo-checkbox-analytics",
                    textBlock2: `Ce fichier cookie est installé par le plugin GDPR Cookie Consent. Le fichier cookie est utilisé pour stocker le consentement de l'utilisateur à l'utilisation des cookies dans la catégorie "Analytique".`
                },
                functional: {
                    num: "",
                    name: "cookielawinfo-checkbox-functional",
                    textBlock2: `Le fichier cookie est installé avec le consentement d'utilisation des cookies GDPR pour enregistrer le consentement de l'utilisateur aux cookies dans la catégorie "Fonctionnels".`
                },
                necessary: {
                    num: "",
                    name: "cookielawinfo-checkbox-necessary",
                    textBlock2: `Ces fichiers cookie sont installés par le plugin GDPR Cookie Consent. Les fichiers cookie sont utilisés pour stocker le consentement de l'utilisateur à l'utilisation des cookies dans la catégorie "Nécessaire".`
                },
                others: {
                    num: "",
                    name: "cookielawinfo-checkbox-others",
                    textBlock2: `Ce fichier cookie est installé par le plugin GDPR Cookie Consent. Le fichier cookie est utilisé pour stocker le consentement de l'utilisateur à l'utilisation des cookies dans la catégorie "Autre".`
                },
                performance: {
                    num: "",
                    name: "cookielawinfo-checkbox-performance",
                    textBlock2: `Le fichier cookie est installé par le plugin GDPR Cookie Consent. Le fichier cookie est utilisé pour stocker le consentement de l'utilisateur à l'utilisation des cookies dans la catégorie "Performance".`
                },
                policy: {
                    num: "",
                    name: "viewed_cookie_policy",
                    textBlock2:
                        "Le fichier cookie est installé par le plugin GDPR Cookie Consent et est utilisé pour stocker des informations sur le consentement de l'utilisateur à l'utilisation des cookies. Il ne stocke aucune donnée personnelle."
                }
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Principaux",
                        text: "Certains fichiers cookie sont nécessaires pour que vous puissiez utiliser toutes les fonctionnalités de notre site Web. Ils nous permettent de maintenir les sessions utilisateur et de prévenir toutes les menaces de sécurité, et ne collectent ni ne stockent aucune information personnelle."
                    },
                    {
                        num: 2,
                        name: "Statistiques",
                        text: "Ces fichiers cookie stockent des informations telles que le nombre de visiteurs du site Web, le nombre de visiteurs uniques, ainsi que des informations sur les pages du site Web visitées, la source de la visite, etc. Ces données nous aident à comprendre et à analyser la performance du site Web et où il peut être amélioré."
                    },
                    {
                        num: 3,
                        name: "Fonctionnels",
                        text: "Ces fichiers cookie aident à certaines fonctions non essentielles sur notre site web. Ces fonctions comprennent l'intégration de contenu, comme des vidéos, ou le partage de contenu du site web sur des plateformes de médias sociaux."
                    },
                    {
                        num: 4,
                        name: "Préférences",
                        text: "Ces fichiers cookie nous aident à enregistrer vos préférences et vos paramètres de navigation, tels que les paramètres de langue, afin de faciliter vos futures visites sur le site web."
                    }
                ]
            }
        }
    },
    conditions: {
        pageName: "Conditions d'utilisation",
        subMenuList: "",
        imageUrls: {
            img1: "./img/useConditionsPage/img1.jpg",
            img1Mobile: "./img/useConditionsPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "",
                textBlock1: `Le service et ses composants développés par la société "Masalovich et partenaires" sont destinés à la surveillance d'Internet, ainsi qu'au traitement et à l'analyse des données ouvertes sur Internet. Nous soulignons que nous n'utilisons que des données ouvertes qui sont disponibles gratuitement sur Internet.`,
                textBlock2: (
                    <div
                        style={{ display: "inline-block", marginBottom: "2px" }}
                    >
                        {`Si vous utilisez notre service de quelque manière que ce soit, vous confirmez ainsi votre accord pour respecter les règles suivantes (toutes les conditions énoncées ci-après s'appliquent à la fois au service dans son ensemble et à chacun de ses composants pris individuellement, mais n'excluent pas les conditions supplémentaires de`}
                        <Link
                            to="/publicOfertaPage"
                            className="ml-5 "
                        >
                            <span className="bborder">l'offre publique</span>
                        </Link>
                        {` ):`}
                    </div>
                ),
                anchor: ""
            },
            support: {
                name: "Conditions du soutien technique",
                line1: "Dans le cadre du soutien technique, le Titulaire des Droits et/ou ses Partenaires s'engagent à fournir les prestations suivantes :\n\r\u0020\u0020\u0020\u2022 mettre à jour les nouvelles versions du Service dans son ensemble et de tous ses composants dès qu'ils sont disponibles ; \n\r\u0020\u0020\u0020\u2022organisation de consultations et résolution d'incidents (par e-mail - support@avl.team et via un formulaire spécial)",
                line2_1:
                    "Horaires pour l'enregistrement des demandes de service dans le système du soutien technique (et à l'adresse e-mail officielle)",
                line2_2: "24 heures sur 24, 7 jours sur 7",
                line3_1: "Horaires pour la résolution des incidents critiques",
                line3_2: "De 10h00 à 20h00 heure de Moscou",
                line4_1:
                    "Horaires pour la résolution d'autres demandes et pour le traitement des demandes d'informations",
                line4_2:
                    "De 10h00 à 20h00 heure de Moscou (sauf week-end et jours fériés)",
                line5_1: "Temps de traitement des demandes",
                line5_2:
                    "4 heures (le temps de réponse aux demandes ne comprend que les heures ouvrables)"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "",
                        text: "En cas d'achat d'un accès au Service, vous avez le droit d'utiliser le Service sur le nombre d'ordinateurs/appareils mobiles spécifié dans la commande."
                    },
                    {
                        num: 2,
                        name: "",
                        text: `La société "Masalovich et partenaires" garantit le fonctionnement du Service conformément aux conditions décrites dans le manuel d'utilisation.`
                    },
                    {
                        num: 3,
                        name: "",
                        text: `La société "Masalovich et partenaires" ne garantit pas le bon fonctionnement du service en cas de violation des conditions décrites dans le manuel d'utilisation, ainsi qu'en cas de violation des conditions d'utilisation du service par l'utilisateur.`
                    },
                    {
                        num: 4,
                        name: "",
                        text: `La société "Masalovich et partenaires" n'est pas responsable des pannes liées à l'utilisation du Service ou de l'incapacité d'utiliser le Service en cas de non-conformité de l'ordinateur de l'utilisateur, des systèmes d'exploitation et des logiciels associés aux exigences système.`
                    },
                    {
                        num: 5,
                        name: "",
                        text: `La société "Masalovich et partenaires" n'est pas responsable de l'utilisation malveillante du Service par l'Utilisateur. Cependant, la société "Masalovich et partenaires" se réserve le droit de surveiller et d'analyser l'activité de l'Utilisateur (de manière anonyme) afin de prévenir toute utilisation du Service à des fins illégales violant la législation de la Fédération de Russie.`
                    },
                    {
                        num: 6,
                        name: "",
                        text: `L'utilisateur s'engage à ne pas utiliser le Service pour tester la sécurité des sites sans l'accord de leurs propriétaires. L'utilisateur est responsable, conformément à la législation de la Fédération de Russie, des tests de sécurité effectués et de toutes les conséquences possibles de ces tests.`
                    },
                    {
                        num: 7,
                        name: "",
                        text: "Il est interdit de procéder à une rétro-ingénierie (reverse engineering), à la décompilation et/ou à la modification du code source de tous nos produits."
                    },
                    {
                        num: 8,
                        name: "",
                        text: "Pour violation des droits d'auteur, le contrevenant est responsable civilement, administrativement ou pénalement conformément à la législation de la Fédération de Russie."
                    },
                    {
                        num: 9,
                        name: "",
                        text: `Après l'activation du compte (à l'exception des versions destinées à des fins d'essai), l'utilisateur a le droit de recevoir un support technique de la part de la société "Masalovich and partenaires" pendant la durée de validité de la licence.`
                    }
                ]
            }
        }
    },
    personalData: {
        pageName: "Accord sur le traitement des données personnelles",
        subMenuList: "",
        imageUrls: {
            img1: "./img/personalDataAgreementPage/img1.jpg",
            img1Mobile: "./img/personalDataAgreementPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Accord sur le traitement des données personnelles",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    confidentionalPolitic: {
        pageName: "Politique de confidentialité",
        subMenuList: "",
        imageUrls: {
            img1: "./img/confidentionalPoliticPage/img1.jpg",
            img1Mobile: "./img/confidentionalPoliticPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Politique de confidentialité concernant le traitement des données personnelles",
                textBlock1: "",
                textBlock2: "",
                anchor: ""
            }
        }
    },
    publicOferta: {
        pageName: "Offre publique",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicOfertaPage/img1.jpg",
            img1Mobile: "./img/publicOfertaPage/img1Mobile.jpg"
        },
        blocks: {
            main: {
                num: "",
                name: "Contrat de service (offre publique)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold" }}>
                            "Date de publication sur le site : 1er janvier 2023.
                        </span>
                        <br />
                        <p>
                            La société à responsabilité limitée "LAVINA PULSE"
                            propose de conclure un accord de fourniture de
                            services pour la fourniture du droit d'utilisation
                            d'une licence simple (non exclusive) pour le
                            produit. La proposition est adressée à un cercle
                            indéfini de personnes physiques capables, de
                            personnes morales, d'entrepreneurs individuels et
                            est une offre publique conformément au paragraphe 2
                            de l'art. 437 du Code civil de la Fédération de
                            Russie.
                        </p>
                    </>
                ),
                anchor: ""
            },
            mainTablet: {
                num: "",
                name: "Contrat de service (offre publique)",
                textBlock1: "",
                textBlock2: (
                    <>
                        <span style={{ fontWeight: "bold", width: "20vw" }}>
                            "Date de publication sur le site : 1er janvier 2023.
                        </span>
                        <br />
                        <p>
                            La société à responsabilité limitée "LAVINA PULSE"
                            propose de conclure un accord de fourniture de
                            services pour la fourniture du droit d'utilisation
                            d'une licence simple (non exclusive) pour le
                            produit. La proposition est adressée à un cercle
                            indéfini de personnes physiques capables, de
                            personnes morales, d'entrepreneurs individuels et
                            est une offre publique conformément au paragraphe 2
                            de l'art. 437 du Code civil de la Fédération de
                            Russie.
                        </p>
                    </>
                ),
                anchor: ""
            }
        }
    },
    tariffs: {
        pageName: "Tarifs",
        subMenuList: [
            {
                num: 1,
                label: "Centre situationnel virtuel",
                anchor: "TariffsPageCenter"
            },
            {
                num: 2,
                label: "Licences et implémentation",
                anchor: "TariffsPageLicence"
            },
            { label: "Soumettre une demande", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/tariffsPage/img1.jpg",
            img1Mobile: "./img/tariffsPage/img1Mobile.jpg",
            img2: "./img/tariffsPage/img2.jpg",
            img2Mobile: "./img/tariffsPage/img2Mobile.jpg"
        },
        blocks: {
            center: {
                num: 1,
                name: "Centre situationnel virtuel",
                textBlock1: "",
                textBlock2:
                    "Le service de surveillance de l'espace Internet offre au client les capacités d'un centre situationnel moderne pour surveiller la situation opérationnelle et détecter rapidement les menaces sur Internet. Pour les clients commerciaux, il existe 3 plans tarifaires disponibles.",
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageCenter"
            },
            licence: {
                num: 2,
                name: "Licences et implémentation",
                textBlock1:
                    "Lors de la mise en œuvre du système sur l'équipement du client, des modifications logicielles individuelles sont possibles conformément aux exigences du client.",
                textBlock2: (
                    <span
                        style={{
                            fontSize: 30 + "px",
                            paddingLeft: 20 + "px",
                            lineHeight: 1.2,
                            position: "relative",
                            top: -5 + "px"
                        }}
                    >
                        Le coût d'une licence serveur sans modifications – 15
                        millions de roubles
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            },
            licenceTablet: {
                num: 2,
                name: "Licences et implémentation",
                textBlock1:
                    "Lors de la mise en œuvre du système sur l'équipement du client, des modifications logicielles individuelles sont possibles conformément aux exigences du client.",
                textBlock2: (
                    <span
                        className="fs-20-20"
                        // style={{
                        //     fontSize: 30 + "px",
                        //     paddingLeft: 20 + "px",
                        //     lineHeight: 1.2,
                        //     position: "relative",
                        //     top: -5 + "px"
                        // }}
                    >
                        Le coût d'une licence serveur sans modifications – 15
                        millions de roubles
                    </span>
                ),
                linkName1: "",
                linkName2: "",
                anchor: "TariffsPageLicence"
            }
        },
        cards: {
            block1: {
                cards: [
                    {
                        num: 1,
                        name: "Développement de l'auteur",
                        text: "La base du système est la technologie originale nationale de surveillance d'Internet et la création des bases de données distribuées. "
                    },
                    {
                        num: 2,
                        name: "Analyse approfondie des données",
                        text: "Un jeu d'algorithmes avancé modernes pour le traitement de données massives y compris l'analyse rapide, les prévisions, la modélisation, etc. "
                    },
                    {
                        num: 3,
                        name: "Flexibilité et adaptabilité",
                        text: "Prend en charge l'intégration avec les bases de données nationales, départementales et spécialisées. Capacité à intégrer des sources de données externes."
                    },
                    {
                        num: 4,
                        name: "Open source",
                        text: "Tous les composants du complexe ont été développés en Russie et sont basés sur des produits logiciels open source."
                    }
                ]
            }
        },
        table: {
            top: {
                column1: {
                    name: "Standard",
                    price: "de 150 mille roubles par mois"
                },
                column2: {
                    name: "Optimum",
                    price: "de 300 mille roubles par mois"
                },
                column3: {
                    name: "Premium",
                    price: "de 500 mille roubles par mois"
                }
            },
            info: [
                {
                    name: "Projet dans le système Avalanche",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Chaîne privée sur Telegram",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Rapport analytique mensuel",
                    standartValue: true,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Soutien analytique",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Rapports analytiques hebdomadaires",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Compilations d'actualités hebdomadaires",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Rapports analytiques",
                    standartValue: false,
                    optimumValue: true,
                    premiumValue: true,
                    optimumValueExtrainfo: "(jusqu'à 3)",
                    premiumValueExtrainfo: "(jusqu'à 5)"
                },
                {
                    name: "Chaîne privée sur Telegram avec une diffusion en direct des actualités clés.",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Bulletins d'information quotidiens",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Surveillance de l'activité des concurrents\n\r(pas plus de 5 entreprises)",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Développement et mise à jour de la carte des risques et des menaces",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Recherche de vulnérabilités de réputation",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                },
                {
                    name: "Travaux supplémentaires à la demande",
                    standartValue: false,
                    optimumValue: false,
                    premiumValue: true,
                    optimumValueExtrainfo: "",
                    premiumValueExtrainfo: ""
                }
            ]
        }
    },
    question: {
        pageName: "Poser une question",
        subMenuList: ""
    },
    request: {
        pageName: "Soumettre une demande",
        subMenuList: ""
    },
    publications: {
        pageName: "Publications",
        subMenuList: "",
        imageUrls: {
            img1: "./img/publicationsPage/img1.jpg",
            img1Mobile: "./img/publicationsPage/img1Mobile.jpg"
        }
    },
    publicActivity: {
        pageName: "Activité publique",
        subMenuList: [
            {
                num: 1,
                label: "Publications",
                anchor: "publicActivityPageMain"
            },
            {
                num: 2,
                label: "Livre «CyberDed sait»",
                anchor: "publicActivityPageBook"
            },
            {
                num: 3,
                label: "Chaînes Telegram",
                anchor: "publicActivityPageTG"
            },
            {
                num: 4,
                label: "Réseaux sociaux",
                anchor: "publicActivityPageSocial"
            },
            { label: "Poser une question", anchor: "askUsQuestion" }
        ],
        imageUrls: {
            img1: "./img/publicActivityPage/img1.jpg",
            img1Mobile: "./img/publicActivityPage/img1Mobile.jpg",
            img2Mobile: "./img/publicActivityPage/img2Mobile.jpg",
            middle1: "./img/publicActivityPage/middle1.jpg",
            middle2: "./img/publicActivityPage/middle2.jpg",
            middle3: "./img/publicActivityPage/middle3.jpg"
        },
        blocks: {
            publications: {
                num: 1,
                name: "Publications",
                textBlock1: "",
                textBlock2: "",
                linkName1: "Voire toutes",
                link1: "/publicationsPage",
                linkName2: "",
                anchor: "publicActivityPageMain"
            },
            cyberDad: {
                num: 2,
                name: `Livre\r\n"CyberDead sait"`,
                textBlock1: "",
                textBlock1Img: "./img/publicActivityPage/small1.jpg",
                imgLink: "./img/publicActivityPage/small1.jpg",
                textBlock2: `Andrei Masalovich, entrepreneur, spécialiste de la cyber intelligence et auteur de la chaîne YouTube "CyberDed", est convaincu que tout échec est toujours un coup cosmique pour rechercher des opportunités. Le principal est d'arrêter de tirer le chat par la queue et de se moucher sur le poing. Plus le point de départ est bas, plus la victoire sera dévastatrice. Ayant traversé les années 90 et 2000, gagnant de l'argent réel pendant les crises, l'auteur vous enseignera les règles de l'antifragilité dans la réalité russe.`,
                linkName1: "Acheter",
                link1: "https://eksmo.ru/book/belyy-pisets-10-sovetov-ot-kiberdeda-kak-priyti-k-uspekhu-v-rossii-ITD1193720/",
                linkName2: "",
                anchor: "publicActivityPageBook"
            },
            tg: {
                num: 3,
                name: "Chaîne Telegram",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageTG"
            },
            social: {
                num: 4,
                name: "Réseaux sociaux",
                textBlock1: "",
                textBlock2: "",
                linkName1: "",
                link1: "",
                linkName2: "",
                anchor: "publicActivityPageSocial"
            }
        },
        cardsBlock: {
            cards: [
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle1.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle2.jpg"
                },
                {
                    num: "",
                    text: "",
                    imgLink: "./img/publicActivityPage/middle3.jpg"
                }
            ]
        },
        telegram: {
            channel1: "Masalovich et partenaires",
            channel1Link: "https://t.me/maspartners",
            channel2: "Mycroft Intelligence",
            channel2Link: "https://t.me/mycroftintel"
        },
        social: {
            channel1: "Dzen CyberDed",
            channel1Link: "https://dzen.ru/kiberded",
            channel2: "VK Masalovich et partenaires",
            channel2Link: "https://vk.com/@mycroftintel-bspltn",
            channel3: "Dzen Mycroft Intelligence",
            channel3Link: "https://dzen.ru/mycroftintel"
        }
    }
};
export default content;
