import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";
import ChangeLang from "../pageBlocks/changeLang";

const FooterHorizontalBlock = ({ handleTheme }) => {
    const { interfaceNames } = useLanguage();
    const blockNames = interfaceNames.footer.horizontalBlock;
    return (
        <div className="footerHorizontalBlock-footer pt-60 pb-60">
            <div className="footerHorizontalBlock-footer-rights fs-14">
                {`© 2024 ${blockNames.name}. ${blockNames.rights}.`}
            </div>
            <div className="footerHorizontalBlock-footer-govlist fs-12">
                <Link
                    to={{
                        pathname:
                            "https://reestr.digital.gov.ru/reestr/305133/?sphrase_id=2597706"
                    }}
                    target="_blank"
                >
                    {blockNames.registry}
                </Link>{" "}
            </div>
            <div className="footerHorizontalBlock-footer-columnsBlock">
                <div className="footerHorizontalBlock-footer-columnsBlock-column">
                    <div
                        className="changeTheme-footer imgBack cp mb-10"
                        onClick={() => handleTheme()}
                    ></div>

                    <div
                        className=" cp mb-10"
                        style={{ height: 10 + "px" }}
                    >
                        <ChangeLang />
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link
                            to={{ pathname: "https://main.avl4.avl.team/" }}
                            target="_blank"
                        >
                            {blockNames.news}
                        </Link>
                    </div>
                    <div className="phoneNum mb-10 cd fs-16">
                        +7(499)393-00-15
                    </div>
                    <div className="email mb-10 fs-16">info@avl.team</div>
                </div>
                <div className="footerHorizontalBlock-footer-columnsBlock-column">
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/forBusinessPage">
                            {blockNames.forBusiness}
                        </Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/platformPage">{blockNames.platform}</Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/safetyPage">{blockNames.safety}</Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        {blockNames.publicActivity}
                    </div>
                </div>
                <div className="footerHorizontalBlock-footer-columnsBlock-column">
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/aboutUsPage">{blockNames.aboutus}</Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/learningPage">{blockNames.learning}</Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/contactsPage">{blockNames.contacts}</Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/cookiesPage">Cookies</Link>
                    </div>
                </div>
                <div className="footerHorizontalBlock-footer-columnsBlock-column">
                    <div className="newsLink cd mb-10 fs-16">
                        <Link to="/confidentionalPoliticPage">
                            {blockNames.confidential}
                        </Link>
                    </div>
                    <div className="newsLink cd mb-10 fs-16">
                        <Link to="/personalDataAgreementPage">
                            {blockNames.agreement}
                        </Link>
                    </div>
                    <div className="newsLink cp mb-10 fs-16">
                        <Link to="/useConditionsPage">
                            {blockNames.useConditions}
                        </Link>
                    </div>

                    {/* <div className="newsLink cd mb-10 fs-16">
                        <Link to="/publicOfertaPage">{blockNames.oferta}</Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default FooterHorizontalBlock;
