import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../hooks/useLang";
// import { useLanguage } from "./hooks/useLang";
import ChangeLangTablet from "./pageBlocksTablet/changeLangTablet";

const MenuBarTablet = ({
    handeDropDownMenu,
    dropdownMenuOpen,
    handleTheme
}) => {
    const location = useLocation();
    const { pathname } = useLocation(); //mr-200
    useEffect(() => {
        if (!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    const { interfaceNames } = useLanguage();

    //////////

    // const [showMenu, setShowMenu]=useState(false)

    //  const handleClick=()=>{
    //     setShowMenu(!showMenu)
    //  }
    //////////////////
    return (
        <>
            {pathname !== `/documentation-avl-3.0/` && (
                <menu
                    className="ma"
                    style={{ width: "100%", padding: " 0 20px" }}
                >
                    <Link to="/">
                        <div
                            className="logoTablet imgBack "
                            onClick={() => handeDropDownMenu(false)}
                        ></div>
                    </Link>
                    <div className="menuItemsBackTablet pb-40 pt-22  gap-50">
                        <div
                            className="changeThemeTablet imgBack cp " // mr-menu
                            onClick={() => handleTheme()}
                            /* style={{ height:10+"px"}}*/
                        ></div>
                        <div
                            className="cp" //mr-menu
                            /* style={{ height: 10 + "px" }}*/
                        >
                            <ChangeLangTablet />
                        </div>
                        <Link
                            className="newsLinkTablet cp fs-14 " //mr-menu
                            to={{ pathname: "https://main.avl4.avl.team/" }}
                            target="_blank"
                        >
                            {interfaceNames.menubar.news}
                        </Link>
                        <div
                            className="phoneNumTablet cd fs-14" //mr-menu
                            style={{ maxWidth: 129 + "px" }}
                        >
                            +7(499)393-00-15
                        </div>
                        <div
                            className="emailTablet cd fs-14 " //mr-menu
                        >
                            info@avl.team
                        </div>
                        <div
                            className={
                                !dropdownMenuOpen
                                    ? "menuBurgerTablet imgBack cp"
                                    : "menuBurgerOpenTablet imgBack cp"
                            }
                            onClick={() => handeDropDownMenu()}
                        ></div>
                    </div>
                </menu>
            )}
        </>
    );
};

export default MenuBarTablet;
